import { jsonMock } from '../mock/json-mock';

var dayjs = require('dayjs');

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration);

var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

import 'core-js/features/array/at';
import { strapi } from './strapi';

let oengus = {
    apiEndpoint: 'https://fungos-api.italianspeedruncommunity.com/api',
    marathonId: '',
    totalHtmlElementId: 'donation-amount-number',
    totalHtmlElement: undefined,
    schedule: [],
    playingNow: undefined,
    nextRun: undefined,
    next5: [],
    devMode: false,
    devAddMinutes: 0,
    oldOffsetHours: 0,
    oldOffsetMinutes: 0,
    toCallOnRefresh: [],
    firstLoad: true,

    callWhenRefreshed: (fn) => {
        oengus.toCallOnRefresh.push(fn);
    },

    getNow: () => {
        let now = oengus.getOengusNow();
        now = now.add(strapi.getOffsetHours(), 'hour').add(strapi.getOffsetMinutes(), 'minute');
        return now;
    },

    getOengusNow: () => {
        let now = dayjs();

        let runDate = dayjs(strapi.getMarathonDate());

        now = now.year(runDate.year()).month(runDate.month()).date(runDate.date());

        if (oengus.devMode)
            now = now.add(oengus.devAddMinutes, 'minute');

        return now;
    },

    getTotalDonationsOffset: () => {
        return strapi.getTotalDonationOffset();
    },

    refreshSchedule: () => {
        // console.log("Refreshing schedule...", oengus.firstLoad);
        if (oengus.firstLoad) {
            // console.log("Refreshing schedule...");
            oengus.refreshScheduleRemote();
            oengus.firstLoad = false;
        }
    },

    refreshScheduleRemote: () => {
        const schedule = oengus.fetchSchedule();
        const getFilteredSchedule = async () => {
            const _schedule = await schedule;
            let now = oengus.getNow();
            const filteredSchedule = _schedule.data
                .filter(l => l.type !== 'setup')
                .sort((a, b) => {
                    return dayjs(a.date).isBefore(b.date) ? -1 : 1;
                });

            oengus.setSchedule(filteredSchedule);

            oengus.toCallOnRefresh.forEach(toCall => { toCall(); });
        };

        getFilteredSchedule();
    },

    refreshScheduleMock: () => {
        const _schedule = JSON.parse(jsonMock.schedule);
        let now = oengus.getNow();
        const filteredSchedule = _schedule.lines.filter(l => {
            return dayjs(l.date).isBetween(
                now.startOf('day'),
                now.endOf('day')
            );
        })
            .filter(l => !l.setupBlock)
            .sort((a, b) => {
                return dayjs(a.date).isBefore(b.date) ? -1 : 1;
            });

        oengus.setSchedule(filteredSchedule);
    },

    setSchedule: (schedule) => {
        let now = oengus.getNow();
        oengus.schedule = schedule;
        // Playing now must be the game selected via strapi
        let currentRun = strapi.getCurrentRun();

        console.log("SCHEDULE:", schedule);

        let foundRun = false;
        let j = 0;
        schedule.forEach(function (run, i) {
            if (!foundRun)
                j = i;

            if (run.id === currentRun.runId)
                foundRun = true;
        });

        const slicedSchedule = schedule.filter((run, i) => i >= j);
        oengus.playingNow = slicedSchedule.at(0);
        console.log(`Oengus schedule index for current run: ${j}`);

        oengus.nextRun = slicedSchedule.at(1);
        oengus.next5 = slicedSchedule.slice(1, 7);

        console.log("Current run:", currentRun, oengus.playingNow);
    },

    refreshScheduleIfOffsetChanged: () => {
        const offsetHours = strapi.getOffsetHours();
        const offsetMinutes = strapi.getOffsetMinutes();
        if (offsetHours !== oengus.oldOffsetHours || offsetMinutes !== oengus.oldOffsetMinutes) {
            oengus.refreshSchedule();
            oengus.oldOffsetHours = offsetHours;
            oengus.oldOffsetMinutes = offsetMinutes;
        }
    },

    updateOffsetInputs: () => {
        document.getElementById('offset-hours').innerHTML = strapi.getOffsetHours();
        document.getElementById('offset-minutes').innerHTML = strapi.getOffsetMinutes();
    },

    showSchedulePreview: () => {
        const runPreviewUl = document.querySelector('#run-preview ul');
        runPreviewUl.innerHTML = '';
        if (typeof oengus.playingNow !== 'undefined') {
            runPreviewUl.appendChild(oengus.getNewPreviewRunElement(oengus.playingNow, 'PLAYING NOW: '));
        }
        let i = 1;
        oengus.next5.forEach(run => {
            runPreviewUl.appendChild(oengus.getNewPreviewRunElement(run, `NEXT RUN #${i}: `));
            i++;
        });

        oengus.showScheduleSubmitForm();
    },

    showScheduleSubmitForm: () => {
        const runPreviewSelect = document.querySelector('#run-preview select');
        if (runPreviewSelect.innerHTML !== '')
            return;

        let i = 1;
        oengus.schedule.forEach(run => {
            let optionElement = document.createElement('option');
            if (oengus?.playingNow?.id === run.id)
                optionElement.setAttribute('selected', 'selected');

            let runners = run.submission?.runners.map(runner => runner.user.name).join(", ")

            let optionString = `#${i}: `;
            if (runners)
                optionString += `${runners} -> `;

            optionString += `${run.name} [${run.submission?.platform?.name}] (${run.submission?.category}) duration: ${oengus.getDurationFromSeconds(run.duration).humanize()}`;

            optionElement.innerHTML = optionString;
            optionElement.value = run.id;
            runPreviewSelect.appendChild(optionElement);
            i++;
        });

        const runPreviewSubmit = document.querySelector('#run-preview input[type=button]');
        runPreviewSubmit.classList.remove('hide');
        runPreviewSubmit.onclick = oengus.submitCurrentRun;
    },

    submitCurrentRun: () => {
        const runPreviewSelect = document.querySelector('#run-preview select');
        let runId = runPreviewSelect.value;
        runId = parseInt(runId);
        const selectedRun = oengus.getRunById(runId);
        console.log('selectedRun', selectedRun);
        if (!!!selectedRun) {
            alert(`!!! COULD NOT FIND RUN ${runId} IN LIST. PLEASE PROCEED MANUALLY !!!`);
            return;
        }

        let now = dayjs();
        const runDate = dayjs(selectedRun.date);

        now = now.year(runDate.year()).month(runDate.month()).date(runDate.date());

        const differenceToNow = dayjs.duration(now.diff(runDate));
        const offsetHours = differenceToNow.hours();
        const offsetMinutes = differenceToNow.minutes();

        const strapiRunId = strapi.gufRunData.filter((run) => run.attributes.runId == runId)[0]?.id;
        console.log(runId, strapiRunId);
        if (!!!strapiRunId) {
            alert("!!! COULD NOT FIND RUN IN LIST. PLEASE PROCEED MANUALLY !!!");
            return;
        }

        strapi.setOffset(offsetHours, offsetMinutes, runDate.format("YYYY-MM-DD"), strapiRunId);
    },

    getNewPreviewRunElement: (run, prefix) => {
        let now = oengus.getNow();
        let startsIn = dayjs.duration(now.diff(run.date))

        const isPast = now.isAfter(run.date);
        const startsInText = isPast ? "started since" : "starts in";

        let runElement = document.createElement('li');
        runElement.innerHTML = `${prefix}${run.name} ${run.submission?.category} (est. ${oengus.getDurationFromSeconds(run.duration).humanize()}, ${startsInText}: ${startsIn.humanize()})`;

        return runElement;
    },

    setMarathonId: () => {
        oengus.marathonId = strapi.getMarathonId();
    },

    fetchSchedule: () => {
        const fetchedSchedule = fetch(
            `${oengus.apiEndpoint}/events/${oengus.marathonId}/schedule/`,
        )
            .then(response => response.json())
            .then(schedule => schedule);
        return fetchedSchedule;
    },

    getPlayingNow: () => {
        const currentRunFromStrapi = strapi.getCurrentRun();

        return oengus.getRunById(currentRunFromStrapi.runId);
    },

    getRunById: (oengusId) => {
        oengusId = parseInt(oengusId);
        console.log(`Searching for run ${oengusId}`);
        let filteredRuns = oengus.schedule.filter(run => {
            return run.id === oengusId;
        });
        console.log(filteredRuns);
        return filteredRuns[0] ?? [];
    },

    getNextRun: (index) => {
        return oengus.next5[index] ?? [];
    },

    updateCurrentTime: () => {
        document.querySelector('#current-time').innerHTML = oengus.getNow().format();
        document.querySelector('#date>.date-date').innerHTML = dayjs().format('DD-MM-YYYY');
        document.querySelector('#time>.date-time').innerHTML = dayjs().format('HH:mm');
    },

    getDurationFromSeconds: (seconds) => {
        return dayjs.duration({ hours: Math.floor(seconds / 3600), minutes: (seconds % 3600) / 60, seconds: seconds % 60 })
    },
};

export { oengus };
window.oengus = oengus;